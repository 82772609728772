<template>
    <b-modal id="form-retur-gudang" title="Form Retur Gudang" no-close-on-backdrop no-close-on-esc>
        <b-form-group label="Pilih Gudang Tujuan Retur">
            <v-select label="text" :reduce="option => option.value" :options="gudangOptions" v-model="gudang_id"></v-select>
        </b-form-group>

        <template #modal-footer>
            <b-button :disabled="!gudang_id" variant="primary" @click.prevent="submit">Simpan</b-button>
        </template>
    </b-modal>
</template>
<script>
import {BModal, BFormGroup, BButton} from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
    components: {
        BModal, BFormGroup, BButton, vSelect
    },
    data: () => ({
        gudang_id: null
    }),
    methods: {
        submit() {
            if(!this.gudang_id) {
                this.displayError({
                    message: 'Harap pilih gudang tujuan retur!'
                })
                return false
            }

            const payload = {
                id_retur_konsumen: this.$route.params.id,
                gudang_id: this.gudang_id
            }

            this.$emit('onChooseGudang', payload)
        }
    },
    computed: {
        gudangOptions() {
            return this.$store.state['retur-konsumen'].gudangOptions
        }
    }
}
</script>