var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('form-gudang', {
    on: {
      "onChooseGudang": _vm.submitFormGudang
    }
  }), _c('section', {
    staticClass: "d-flex justify-content-between align-items-center mb-2"
  }, [_c('h2', [_vm._v("Detail Retur Konsumen")]), _vm.retur.selesai > 0 && !_vm.canApprove ? _c('b-button', {
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openFormGudang($event);
      }
    }
  }, [_vm._v("Kirim ke Gudang")]) : _vm._e()], 1), _c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nota Penjualan"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.retur.penjualan ? _vm.retur.penjualan.no_kwitansi : '-'))])]), _c('b-form-group', {
    attrs: {
      "label": "Tanggal Retur"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.retur.tanggal))])])], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Konsumen"
    }
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.retur.konsumen ? _vm.retur.konsumen.nama_toko : '-') + " ")])]), _c('b-form-group', {
    attrs: {
      "label": "Status Retur"
    }
  }, [_vm.retur.selesai > 0 ? _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" SELESAI ")]) : _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" BELUM SELESAI ")])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Sales"
    }
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.retur.sales ? _vm.retur.sales.nama_lengkap : '-') + " ")])]), _c('b-form-group', {
    attrs: {
      "label": "Keterangan"
    }
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.retur.keterangan) + " ")])])], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "3"
    }
  }, [_c('span', {
    staticClass: "d-block mb-1 text-center"
  }, [_vm._v("Total Retur")]), _c('h2', {
    staticClass: "text-center text-danger"
  }, [_c('strong', [_vm._v(" Rp " + _vm._s(_vm.formatRupiah(_vm.totalHarga)) + " ")])])])], 1)], 1), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-1"
  }, [_c('h2', [_vm._v("Rincian Retur (" + _vm._s(_vm.retur.rincian ? _vm.retur.rincian.length : 0) + ")")]), _vm.checkedBarangs.length > 0 ? _c('b-button', {
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.massRemove($event);
      }
    }
  }, [_vm._v("Hapus")]) : _vm._e()], 1), _c('b-card', [_c('validation-observer', {
    ref: "form-barang-retur"
  }, [_c('b-table', {
    attrs: {
      "responsive": "",
      "striped": "",
      "bordered": "",
      "fields": _vm.fields,
      "items": _vm.retur.rincian
    },
    scopedSlots: _vm._u([{
      key: "table-colgroup",
      fn: function (_ref) {
        var fields = _ref.fields;
        return _vm._l(fields, function (field) {
          return _c('col', {
            key: field.key,
            style: {
              width: field.key == 'no' ? '10%' : 'auto',
              padding: '0.72rem'
            }
          });
        });
      }
    }, {
      key: "cell(checkbox)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.checkedBarangs,
            callback: function ($$v) {
              _vm.checkedBarangs = $$v;
            },
            expression: "checkedBarangs"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function (_ref3) {
        var index = _ref3.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(barang)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.nama : '-') + " ")];
      }
    }, {
      key: "cell(harga_jual)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(("Rp " + (_vm.formatRupiah(item.harga_jual)))) + " ")];
      }
    }, {
      key: "cell(stoks)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.stoks && item.stoks.length > 0 ? _vm.getTotalStok(item.stoks) : 0) + " " + _vm._s(item.barang && item.barang.satuan ? item.barang.satuan.satuan.toLowerCase() : '') + " ")];
      }
    }, {
      key: "cell(qty)",
      fn: function (_ref7) {
        var item = _ref7.item,
          index = _ref7.index;
        return [_vm.editAction ? _c('validation-provider', {
          attrs: {
            "name": "tanggal_waktu",
            "rules": ("max_value:" + (item.qty))
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref8) {
              var errors = _ref8.errors;
              return [_c('b-form-input', {
                attrs: {
                  "state": errors.length > 0 ? false : null,
                  "type": "number"
                },
                on: {
                  "keyup": function ($event) {
                    return _vm.onChangeQty(index);
                  }
                },
                model: {
                  value: item.custom_qty,
                  callback: function ($$v) {
                    _vm.$set(item, "custom_qty", $$v);
                  },
                  expression: "item.custom_qty"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        }) : _c('span', [_vm._v(_vm._s(item.qty))])];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref9) {
        var index = _ref9.index,
          item = _ref9.item;
        return [_vm.editAction && _vm.isHaveKonversi(item.barang) && _vm.getTotalStok(item.stoks) > 0 ? _c('b-form-select', {
          attrs: {
            "options": _vm.getKonversi(item.barang),
            "label": "text"
          },
          on: {
            "change": function ($event) {
              return _vm.onChangeKonversi(index);
            }
          },
          model: {
            value: item.selectedKonversi,
            callback: function ($$v) {
              _vm.$set(item, "selectedKonversi", $$v);
            },
            expression: "item.selectedKonversi"
          }
        }) : _c('span', [_vm._v(" " + _vm._s(item.barang && item.barang.satuan ? item.barang.satuan.satuan : '-') + " ")])];
      }
    }, {
      key: "cell(converted)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.converted ? item.converted : item.qty) + " /" + _vm._s(_vm.satuanTerkecil(item)) + " ")];
      }
    }, {
      key: "cell(total_harga)",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(("Rp " + (_vm.formatRupiah(parseInt(item.harga_jual) * parseInt(item.qty))))) + " ")];
      }
    }, {
      key: "cell(stocks)",
      fn: function (_ref12) {
        var toggleDetails = _ref12.toggleDetails,
          item = _ref12.item;
        return [!item.placed_at || item.placed_at.length < 1 ? _c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return toggleDetails($event);
            }
          }
        }, [_vm._v("Pilih Stok")]) : _c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return toggleDetails($event);
            }
          }
        }, [_vm._v("Detail Penempatan")])];
      }
    }, {
      key: "row-details",
      fn: function (_ref13) {
        var item = _ref13.item,
          toggleDetails = _ref13.toggleDetails;
        return [_c('b-card', [!item.placed_at || item.placed_at.length < 1 ? _c('table', [_c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v("Gudang")]), _c('th', [_vm._v("Blok")]), _c('th', [_vm._v("Palet")]), _c('th', [_vm._v("Rak")]), _c('th', [_vm._v("Stok")]), _c('th', [_vm._v("Stok Disetujui Gudang")])]), _vm._l(item.stocks, function (stock, i) {
          return _c('tr', {
            key: i
          }, [_c('td', [_c('b-form-checkbox', {
            model: {
              value: stock.selected,
              callback: function ($$v) {
                _vm.$set(stock, "selected", $$v);
              },
              expression: "stock.selected"
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s(stock.gudang ? stock.gudang.nama_gudang : '-') + " ")]), _c('td', [_vm._v(_vm._s(stock.blok ? ("Block " + (stock.blok.blok)) : '-'))]), _c('td', [_vm._v(_vm._s(stock.palet ? ("Palet " + (stock.palet.palet)) : '-'))]), _c('td', [_vm._v(_vm._s(stock.rak ? ("Rak " + (stock.rak.rak)) : '-'))]), _c('td', [_vm._v(_vm._s(stock.stok))]), _c('td', [_c('b-form-input', {
            model: {
              value: stock.selectedStock,
              callback: function ($$v) {
                _vm.$set(stock, "selectedStock", $$v);
              },
              expression: "stock.selectedStock"
            }
          })], 1)]);
        })], 2) : _c('table', [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Blok")]), _c('th', [_vm._v("Palet")]), _c('th', [_vm._v("Rak")]), _c('th', [_vm._v("Laci")]), _c('th', [_vm._v("Stok")])])]), _c('tbody', _vm._l(item.placed_at, function (stock, i) {
          return _c('tr', {
            key: i
          }, [_c('td', [_vm._v(_vm._s(++i))]), _c('td', [_vm._v(_vm._s(_vm.getPenyimpananName(stock === null || stock === void 0 ? void 0 : stock.id_penyimpanan, 'blok')))]), _c('td', [_vm._v(_vm._s(_vm.getPenyimpananName(stock === null || stock === void 0 ? void 0 : stock.id_penyimpanan, 'palet')))]), _c('td', [_vm._v(_vm._s(_vm.getPenyimpananName(stock === null || stock === void 0 ? void 0 : stock.id_penyimpanan, 'rak')))]), _c('td', [_vm._v(_vm._s(_vm.getPenyimpananName(stock === null || stock === void 0 ? void 0 : stock.id_penyimpanan, 'laci')))]), _c('td', [_vm._v(_vm._s(stock.jumlah))])]);
        }), 0)]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-secondary"
          },
          on: {
            "click": toggleDetails
          }
        }, [_vm._v(" Hide ")])], 1)];
      }
    }])
  })], 1)], 1), _vm.retur.selesai == 0 && _vm.isSales && _vm.retur.rincian.length > 0 ? _c('section', {
    staticClass: "d-flex justify-content-end"
  }, [_vm.editAction ? _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.editAction = false;
      }
    }
  }, [_vm._v("Batal")]) : _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.selesaikan($event);
      }
    }
  }, [_vm._v("Tandai Selesai")]), _vm.editAction ? _c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": _vm.retur.selesai > 0
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v("Simpan Perubahan")]) : _c('b-button', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.editAction = true;
      }
    }
  }, [_vm._v("Edit")])], 1) : _vm._e(), _vm.retur.selesai > 0 && _vm.canApprove && _vm.retur.rincian.length > 0 ? _c('section', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.approveStock($event);
      }
    }
  }, [_vm._v("Simpan")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }